<template>
  <div class="auth-wrapper auth-v1 auth-back px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <!-- Forgot Password v1 -->
      <b-link class="brand-logo">
        <!-- logo -->
        <vuexy-logo />
      </b-link>
      <b-card class="mb-0 p-1">
        <b-card-title class="mb-1">
          Establece una nueva contraseña 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Tu contraseña debe de tener almenos 1 caracter especial, 1 mayúscula, 1 minúscula y 1 número.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="login-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="login-email"
                  placeholder="juan@ejemplo.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label="Nueva contraseña"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Repetir contraseña"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
              :disabled="submitting"
            >
              <b-spinner
                v-if="submitting"
                small
              />
              Establecer contraseña
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Regresar al inicio
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      token: '',
      userEmail: '',
      cPassword: '',
      password: '',
      submitting: false,
      // validation
      required,
      email,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    const parameters = new URLSearchParams(window.location.search)
    if (!parameters.has('token')) {
      router.push({ name: 'login' })
      return
    }
    this.token = parameters.get('token')
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      const self = this
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          self.submitting = true
          store.dispatch('account/resetPassword', {
            token: self.token,
            email: self.userEmail,
            password: self.password,
            password_confirmation: self.cPassword,
          }).then(
            response => {
              self.submitting = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'CheckCircleIcon',
                  text: response.message,
                  variant: 'success',
                },
              },
              {
                position: 'bottom-center',
                timeout: 10000,
              })
              router.push({ name: 'login' })
            },
            error => {
              self.submitting = false
              const { response } = error
              const firstError = response.data.errors[Object.keys(response.data.errors)[0]][0]
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notificación',
                  icon: 'AlertCircleIcon',
                  text: firstError,
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-center',
                timeout: 10000,
              })
            },
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.auth-back{
  background-image: url('~@/assets/images/pages/login.jpg')!important;
  background-position: center;
  height: 100vh!important;
  width: 100%!important;
}
.auth-v1 {
  .auth-inner {
    &:before {
      background-image: none!important;
    }
  }
}
</style>
